export class Claim {
    customerRef: string;
    claimType: string;
    claimTypeData: string;
    subType: string;
    subClaimType: string;
    schemeName:string;
    // subclaimType: string;
    claimMode: string;
    travelDate: string;
    incidentDate: string;
    incidentAddress: string;
    incidentCountry: string;
    incidentState: string;
    incidentCity: string;
    travelPolicyRef: string;
    NameOfCorporate: string;
    travelCaseRef: string;
    CreatedBy: string;
    policyNumber: string;
    customerName: string;
    caseId: string;
    caseNumber: string;
    caseAssingedTo: string
    status: String;
    travelCaseId: string;
    insuranceProvider: string;
    contactNumber: string;
    countryNumber: string;
    contactEmail: string;
    initialReservAmountUSD: string;
    hospital: string;
    hospitaltype: string;
    claimedAmount: string;
    hospitalAddress: string;
    hospitalCountry: string;
    hospitalState: string
    hospitalCity: string;
    hopsitalZipCode:string;
    CreationDate: string;
    passportNo: string
    claimDescription: string;
    certificateNumber: string;
    claimNote: String;
    claimReported: string;
    claimEvent: String;
    caseType: String;
    overseaPartners:String;
    serviceInvoice:String;
    currency:String;
    policyStartDate:string;
    ClaimantDob:string;
    claimAmount:string;
    subLimitMapping:string;
    policyType:string;
    claimantGender:string;
    currencyICA: string;
    currencyINR: string;
    InsuredClaimAmount: string;
    initialReservAmountINR: string;
    conversionRateInitial: string;
    conversionTypeInitial: String;
    incidentRegion: string;
    hospitalEmail: string;
    hospitalPhone: string;
    hospitalRegion: string;
    SumInsured: number;
    localCurrency: string;
}

export class UpdateClaim {
    caseNumber: string;
    status: String;
    policyNumber: string;
    customerName: string;
    claimDate: string;
}
export class UpdateClaimForm {
    customerRef: string;
    claimType: string;
    claimTypeData:string;
    subType: string;
    claimMode: string;
    schemeName:string;
    claimAmount:string;
    InsuredClaimAmount: Number;
    initialReservAmountUSD: Number;
    initialReservAmountINR: Number;

    initialAmountUSD: Number;
    initialAmountINR: Number;
    insuredAmount: Number;

    conversionTypeInitial: String;
    conversionRateInitial: Number;
    travelDate: Date;
    incidentDate: Date;
    claimDescription: string;
    claimNote: String;
    claimEvent: String;
    caseType: String;
    caseValidation: string;
    caseAssingedTo: string;
    incidentAddress: string;
    incidentCountry: string;
    incidentState: string;
    incidentCity: string;
    hospital: string;
    hospitaltype: string;
    hospitalType:string;
    hospitalCity:string;
    hospitalCountry:string;
    hospitalState:string;
    hospitalRegion:string;
    hopsitalZipCode:string;
    hospitalAddress: string;
    contactEmail: string;
    contactNumber: string;
    countryNumber: string;
    currentPhoneNumber: string;
    currentCountryNumber: string;
    certificateNumber: string;
    travelPolicyRef: string;
    travelCaseRef: string;
    CreatedBy: string;
    CreatedDate: Date;
    policyNumber: string;
    customerName: string;
    caseId: string;
    policyid: string;
    caseNumber: string;
    status: String;
    travelCaseId: string;
    insuranceProvider: string;
    clstatus: string;
    claimSatus: string;
    responseToClient: string;
    fromCurrency: string;
    currencyICA: string;
    currencyINR: string;
    dateOfIntimation: string;
    eaiClaimNo: string;
    nameOfCorporate: string;
    policyType: string;
    plan: string;
    subPlan: string;
    mmid: string;
    coverage: string;
    eaReferenceNo: string;
    localCurrency: string;
    sumInsured: string;
    branchCode: string;
    productCode: string;
    dateOfPolicyIssuance: Date;
    dateOfPolicyInception: Date;
    policyStartDate:string;
    dateOfPolicyExpiry: Date;
    agentLocation: string;
    passportNo: string;
    claimantGender: string;
    ClaimantDob: Date;
    firstDocument: Date;
    lastDocument: Date;
    claimStatusChangeDate: Date;
    age: string;
    ageGroup: string;
    panCard: string;
    aadharCard: string;
    dateOfLoss: string;
    typeOfLoss: string;
    insuredLocation: string;
    insuredState: string;
    placeOfLossCity: string;
    countryOfLoss: string;
    regionOfLoss: string;
    incidentRegion: string;
    regionType: string;
    diagnosisIcd9: string;
    diagnosisDesc: string;
    bankName: string;
    branchName: string;
    accountNumber: string;
    ifscCode: string;
    micrCode: string;
    bankAccountHolderName: string;
    payeeName: string;
    modeOfPayment: string;
    subLimitMapping: string;
    currentDiagnosis: string;
    admissionDate: string;
    dischargeDate: string;
    totalClaimAmount: string;
    claimedAmount: string;
    initialClaimReserve_usd: string;
    initialClaimReserve_inr: string;
    totalInvoiceUsd: string;
    finalReserve_Usd: string;
    finalReserve_Inr: string;
    reasonForRepudiation: string;
    typeOfTreatment: string;
    docReceivedDate: string;
    dateOfDeficiencyLetter: Date;
    firstReminderLetter: Date;
    secondReminderLetter: Date;
    dateOfClosingLetter: Date;
    reopenDate: Date;
    gopIssueDate: Date;
    receiptOfCompleteSetDocDate: Date;
    sendingDocToRGICLDate: Date;
    approvalReceivedDate_RGICL: Date;
    // payable: string;
    notPayable: string;
    currency: string;
    finalPayInUsd_Euros: string;
    finalPayment_rs: string;
    finalPayment: string;
    stdDed_USD: string;
    stdDed_Rs: string;
    otherDeductionInUSD: string;
    otherDeductionInRs: string;
    chequeNo: string;
    chequeDate: Date;
    settlementDate: Date;
    chequeDespatchedInsured: Date;
    fundReceivedFromClient: Date;
    debitNoteRaised: Date;
    nocReminder1: Date;
    nocReminder2: Date;
    nocClosingDate: Date;
    nocReOpenDate: Date;
    requestStatus: string;
    remarks: string;
    genratorEvent: string;
    lastDocReceivedFormInsured: string;
    caseclientApprovedAmount: string;
    clientRemark: string;
    updatedBy: string;
    subClaimType: string;
    claimReported: String;
    geographicalScope: String;
    overseaPartners:String;
    serviceInvoice:String;
    previousData:String;
    // claimReported:String
    
}
export class Cas {
    insuredName: String;
    age: String;
    gender: String;
    eaiFileNo: String;
    policyPeriodFromm: Date;
    policyPeriodTo: String;
    Sublimit: String;
    exclusions: String;
    notificationDate: Date;
    diagnosis: String;
    dateFistSymptom: Date;
    dateFistConsultation: Date;
    dateOfAdmission: Date;
    dateOfDischarge: Date;
    chiefComplaints: String;
    pastMedicalHistory: String;
    clinicalFindings: String;
    investigation: String;
    treatment: String;
    otherReasons: String;
    notApprovedReason: String;
    denielLetter: String;
    travelCaseId: string;
    doctorEmailAddress: String;
    localMedicalOffcier: String;
    doctorName: String;
    address: String;
    phone: String;
    previousData: String;
}

export class Customer {
    firstName: String;
    lastName: String;
    contactNumber: number;
    countryNumber: Number;
    contactEmail: String;
    gender: String;
    passportNumber: String;
    dateOfBirth: String;
    address1: String;
    address2: String;
    city: String;
    state: String;
    country: String;
    zip: String;
    schemaRefId: String;
    policyNumber: String;
    policyIssuedOn: String;
    policyStartsOn: String;
    policyEndsOn: String;
    previousData: any;
}

export class Member {
    firstName: String;
    lastName: String;
    contactNumber: number;
    countryNumber: Number;
    contactEmail: String;
    gender: String;
    passportNumber: String;
    dateOfBirth: String;
    address1: String;
    address2: String;
    city: String;
    state: String;
    country: String;
    zip: String;
    schemaRefId: String;
    policyNumber: String;
    policyIssuedOn: String;
    policyStartsOn: String;
    policyEndsOn: String;
    travelPoliciesRef: String;
    previousData :String;
}

export class CustomerSearch {
    firstName: String;
    contactEmail: String;
    passportNumber: String;
    dateOfBirth: String;
    pls_Customer_Id: String
}

export class updatePayable {
    submittedAmountInVat: String;
    fromCurrency: String;
    adjuctionAmount: String;
    toCurrency: String;
    incidentDate: String;
    conversionRate: String;
    inEligibleCharges: String;
    paidByPrimaryCarrier: String;
    discount: String;
    approvedAmount: String;
    deductable: String;
    internalComments: String;
    ExternalComments: String;
    payableAmount: String;
    payableCurrency: String;
    vat: String;
    vatAmount: String;
    amountWithOutVat: String;
    travelCaseId: string;
    payableIncidentDate: String;
    payableIconversionRate: String;
}