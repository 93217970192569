import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  insuranceProviders = null

  public exportAsExcelFile(json: any[], excelFileName: string): void {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }  
  private saveAsExcelFile(buffer: any, fileName: string): void {  
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
     FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);  
  }

  
  getCustomerDetails(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.inboundCustomers,data).pipe(map(data => {
      return data;
    }));
  }
  getCustomerDetailsById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.inboundCustomers +`?customerId=${id}`).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetailsById +`?Pls_Customer_Id=${id}`).pipe(map(data => {
      return data;
    }));
  }
  getInsuranceProviders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.insuranceProviders).pipe(map(data => {
      this.insuranceProviders = data
      return data;
    }));
  }
  getInboundInsuranceProviders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.InboundInsuranceProviders).pipe(map(data => {
      return data;
    }));
  }
  getDoctors() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDoctors).pipe(map(data => {
      return data;
    }));
  }
  getFields() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFields).pipe(map(data => {
      return data;
    }));
  }
  uploadBulkData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.uploadBulkData, data).pipe(map(data => {
      return data;
    }));
  }

  removeBulkData() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.removeBulkData).pipe(map(data => {
      return data;
    }));
  }

  uploadBulkDataCases(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.uploadBulkDataCases, data).pipe(map(data => {
      return data;
    }));
  }
  saxDataUpload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saxDataUpload, data).pipe(map(data => {
      return data;
    }));
  }

  mchiTravelDataUpload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.mchiTravelDataUpload, data).pipe(map(data => {
      return data;
    }));
  }

  saveRawData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveRawData, data).pipe(map(data => {
      return data;
    }));
  }
  getLastCustomerId() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.lastCustomerId).pipe(map(data => {
      return data;
    }));
  }
  getLastCaseId(clientName) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.lastCaseId + '?clientName=' + clientName).pipe(map(data => {
      return data;
    }));
  }  
  fileTrackerUpload(file: File) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);      
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadFileTrackerData+'?docTypeId=FileTrakarCSV', formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  fileTrackerImageUpload(file:any) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      // formData.append('imgUploader', file);  
      for(const files of file){
        formData.append('imgUploader',files)
      }       
      return this.http.post(this.baseUrl + environment.apiEndPoints.fileTrackerImageUpload, formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }
  // fileTrackerUpload(data) {
  //   return this.http.post(this.baseUrl + environment.apiEndPoints.uploadFileTrackerData, data).pipe(map(data => {
  //     return data;
  //   }));
  // }  
  getCountries() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.countries).pipe(map(data => {
      return data;
    }));
  }
  getClientSchemes(clientId: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClientSchemes + '?clientId=' + clientId).pipe(map(data => {
      return data;
    }));
  }
  getCaseDocuments(CaseNumber: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCaseDocuments + '?CaseNumber=' + CaseNumber).pipe(map(data => {
      return data;
    }));
  }
  getDocumentsList(claimType: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocumentsList + '?claimtype=' + claimType).pipe(map(data => {
      return data;
    }));
  }
  getSchedularJob() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getSchedularJob).pipe(map(data => {
      return data;
    }));
  }
  getTemplate() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getTemplate).pipe(map(data => {
      return data;
    }));
  }
  getCommunication() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCommunication).pipe(map(data => {
      return data;
    }));
  }
  getchannel() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.listAllSenderChannels).pipe(map(data => {
      return data;
    }));
  }
  updateSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSchedularJob, data).pipe(map(data => {
      return data;
    }));
  }
  createcommunication(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createcommunication, data).pipe(map(data => {
      return data;
    }));
  }
  updateTemplateByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateTemplateByClientID, data).pipe(map(data=>{
      return data;
    }))
  }

  updateCommunicationByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCommunicationByClientID, data).pipe(map(data=>{
      return data;
    }))
  }
  
  updateChannelByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateChannelByClientID, data).pipe(map(data=>{
      return data;
    }))
  }
  getLogByType(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getLogByType, data).pipe(map(data=>{
      return data;
    }))
  }
  getLogComment(id:any) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getLogComment + '?_id=' + id).pipe(map(data => {
      return data;
    }));
  }
  getStates(id:any) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getStates + '?CountryCode=' + id).pipe(map(data => {
      return data;
    }));
  }
  // getMasters: "masters/getMasters"
  getMasters(data) {
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;

    return this.http.post(this.baseUrl + environment.apiEndPoints.getMasters, data).pipe(map(data => {
      return data;
    }));
  }
  getMastersAll(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMastersAll, data).pipe(map(data => {
      return data;
    }));
  }  
  getMastersClientWise(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMastersClientWise, data).pipe(map(data => {
      return data;
    }));
  }
  downloadAllLogForPdf(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.downloadAllLogForPdf+"?travelCaseId="+data).pipe(map(data => {
      return data;
    }));
  }

  getGeneralMasters() {
    //   let requestObject = {
    //     "projectName": "EzTravel",
    //     "generalMasterName": "status"
    // }
    // let projectName = data['projectName'] + '?projectName=' +projectName+'?generalMasterName=' +generalMasterName
    // let generalMasterName = data['generalMasterName']
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;
    // console.log(queryParams,'API');

    return this.http.get(this.baseUrl + environment.apiEndPoints.getGeneralMasters).pipe(map(data => {
      return data;
    }));
  }
  getEclaimsGeneralMasters() {
    //   let requestObject = {
    //     "projectName": "EzTravel",
    //     "generalMasterName": "status"
    // }
    // let projectName = data['projectName'] + '?projectName=' +projectName+'?generalMasterName=' +generalMasterName
    // let generalMasterName = data['generalMasterName']
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;
    // console.log(queryParams,'API');

    return this.http.get(this.baseUrl + environment.apiEndPoints.getEclaimsGeneralMasters).pipe(map(data => {
      return data;
    }));
  }

  getDatesasPerLogs(travelCaseId) {
      return this.http.get(this.baseUrl + environment.apiEndPoints.getDatesasPerLogs + '?travelCaseId=' + travelCaseId).pipe(map(data => {
      return data;
    }));
  }
  addGeneralMasters(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addGeneralMasters, data).pipe(map(data => {
      return data;
    }));
  }
  addEclaimsGeneralMasters(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addEclaimsGeneralMasters, data).pipe(map(data => {
      return data;
    }));
  }
  updateGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  updateEclaimsGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateEclaimsGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsXl(data) {

    // JSON.stringify(data)
    // "clientName": "MCHA",
    //   "startDate" : "2021-03-04T12:46:32.000",
    //   "endDate" : "2021-03-16T12:36:22.000"
    // let clientDet = data['clientName']
    // let startDate = data['startDate']
    // let endDate = data['endDate']
    // return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetailsXl+'/?clientName='+clientDet+'&startDate='+startDate+'&endDate='+endDate).pipe(map(data => {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimDetailsXl, data).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsNew(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimDetailsNew, data).pipe(map(data => {
      return data;
    }));
  }
  getInboundCasesReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundCasesReports, data).pipe(map(data => {
      return data;
    }));
  }
  getInboundReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundReports, data).pipe(map(data => {
      return data;
    }));
  }
  getCSATReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getCSATReports,data).pipe(map(data => {
      return data;
    }));
  }

  getFinanceReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getFinanceReport,data).pipe(map(data => {
      return data;
    }));
  }
  

  getTaskListDownload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownload, data).pipe(map(data => {
      return data;
    }));
  }

  getLogsReports(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.logsReportsDownload,data).pipe(map(data=>{
      return data;
    }))
  }
  getFileTrackerFailureReports(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.fileTrackerFailureReport,data).pipe(map(data=>{
      return data;
    }))
  }
  getFileTrackingData(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getFileTrackingData,data).pipe(map(data=>{
      return data;
    }))
    //https://eztraveluatapi.europassistance.in/api//getFileTrackingData
  }
  getLogWiseData(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getLogWiseData,data).pipe(map(data=>{
      return data;
    }))
  }
  getEclaimReport(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getEclaimReport,data).pipe(map(data=>{
      return data;
    }))
  }
  getTaskListPending(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownloadPending, data).pipe(map(data => {
      return data;
    }));
  }

  getTaskListCompleted(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownloadCompleted, data).pipe(map(data => {
      return data;
    }));
  }

  getMISReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMISreport, data).pipe(map(data => {
      return data;
    }));
  }
  getDebitNoteReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDebitNoteReport, data).pipe(map(data => {
      return data;
    }));
  }

  getDefiiencyReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDefiiencyReport, data).pipe(map(data => {
      return data;
    }));
  }
  getDebitNoteReportAdityaBirla(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDebitNoteReportAdityaBirla, data).pipe(map(data => {
      return data;
    }));
  }

  getClaimIntimationReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimIntimationReport, data).pipe(map(data => {
      return data;
    }));
  }

  getMiscellaneousReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMiscellaneousReport, data).pipe(map(data => {
      return data;
    }));
  }
  
  searchGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  searchEclaimsGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchEclaimsGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  getReport(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getReport, data).pipe(map(data => {
      return data;
    }));
  }
  saveButtonsList(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveeclaimsbutton,data).pipe(map(data => {
      return data;
    }));
  }

  createSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createSchedularJob,data).pipe(map(data => {
      return data;
    }));
  }

  getEmailTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getemailtemplates).pipe(map(data => {
      return data;
    }));
  }
  getSMSTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getsmstemplates).pipe(map(data => {
      return data;
    }));
  }
  deleteTemplateNoteById(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.deleteTemplateNoteById+"?_id="+data).pipe(map(data => {
      return data;
    }));
  }
  getManualTemplate(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getManualTemplate,data).pipe(map(data => {
      return data;
    }));
  }
  getManualTemplateForClaimProcess(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getManualTemplateForClaimProcess,data).pipe(map(data => {
      return data;
    }));
  }
  saveClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }
  updateClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }
  getClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }

  getTemplateForCsat(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getCsatTemplate,data).pipe(map(data => {
      return data;
    }));
  }

  updateTemplateNote(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateTemplateNote,data).pipe(map(data => {
      return data;
    }));
  }

  createEmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  createSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createsmstemplates,data).pipe(map(data => {
      return data;
    }));
  }

  createTemplateNote(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.createTemplateNote,data).pipe(map(data => {
      return data;
    }))
  }
  getTemplateNotes(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getTemplateNotes).pipe(map(data => {
      return data;
    }))
  }
  updateEmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  updateSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatesmstemplates,data).pipe(map(data => {
      return data;
    }));
  }

  deleteWmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  deleteSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletesmstemplates,data).pipe(map(data => {
      return data;
    }));
  }
  
  createTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createTemplate,data).pipe(map(data => {
      return data;
    }));
  }
  createChannel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createChannel,data).pipe(map(data => {
      return data;
    }));
  }
  

  getRuleEngine() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRuleEngineFlightDelay).pipe(map(data => {
      return data;
    }));
  }

  createRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }

  validateRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.validateRuleEngine,data).pipe(map(data => {
      return data;
    }));
  }

  validateRuleEngineCancellation(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.validateRuleEngineforCancellation,data).pipe(map(data => {
      return data;
    }));
  }

  

  updateRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }

  deleteRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }


  getRuleEngineFlightCancel() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRuleEngineFlightCancel).pipe(map(data => {
      return data;
    }));
  }

  createRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }

  updateSRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }

  deleteRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }


  getButtonsList() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.geteclaimsbuttonlist).pipe(map(data => {
      return data;
    }));
  }
  updateclaimsbuttonlistByClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateclaimsbuttonlistByClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteclaimsbuttonlistByClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteclaimsbuttonlistByClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteInboundClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteInboundClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteDoctor(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDoctor,data).pipe(map(data => {
      return data;
    }));
  }

  deleteSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteSchedularJob,data).pipe(map(data => {
      return data;
    }));
  }
  deleteTemplate(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteTemplate,data).pipe(map(data=>{
      return data;
    }))
  }
  deleteCommunication(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteCommunication,data).pipe(map(data=>{
      return data;
    }))
  }
  deleteChannel(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteChannel,data).pipe(map(data=>{
      return data;
    }))
  }
  DocumentsList(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocuments).pipe(map(data => {
      // console.log(data,"aaklj")
      return data;
    }));
  }
  DocumentsListForSubclaims(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocumentsForSubclaims).pipe(map(data => {
      // console.log(data,"aaklj")
      return data;
    }));
  }
  saveDocumentsList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }
  saveCaseDocs(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveCaseDocs,data).pipe(map(data => {
      return data;
    }));
  }
  removeCaseDocs(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.removeCaseDocs,data).pipe(map(data => {
      return data;
    }));
  }
  addCommentToDoc(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.addCommentToDoc,data).pipe(map(data => {
      return data;
    }));
  }
  addCaseDocumentDeficiency(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.addCaseDocumentDeficiency,data).pipe(map(data => {
      return data;
    }));
  }
  updateDocumentComments(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentComments,data).pipe(map(data => {
      return data;
    }));
  }
  
      
  updateDocumentsList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }
  deleteItemFromDocumentList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteItemFromDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }

  saveDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  saveCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addcountry,data).pipe(map(data => {
      return data;
    }));
  }
  updateCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatecountry,data).pipe(map(data => {
      return data;
    }));
  }

  deleteCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletecountry,data).pipe(map(data => {
      return data;
    }));
  }

  saveState(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addstate,data).pipe(map(data => {
      return data;
    }));
  }

  deleteState(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletestate,data).pipe(map(data => {
      return data;
    }));
  }
   
  updateDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  deleteDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  getPolicyIById(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getPolicyById, data).pipe(map(data => {
      return data;
    }));
  }

  updateCustomerName(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCustomerName, data).pipe(map(data => {
      return data;
    }));
  }
  updateInboundCustomerName(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundCustomerName, data).pipe(map(data => {
      return data;
    }));
  }

  updateDateofLossFromService(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDateofLossfromService, data).pipe(map(data => {
      return data;
    }));
  }


  getIFSCDetails(ifsc: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.ifscdetails + '?ifsc=' + ifsc).pipe(map(data => {
      return data;
    }));
  }

  

  sendMessage(form) {
    // console.log('came to service');    
    // let url = `https://api.infobip.com/email/2/send`;

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'authorization': 'Basic RVVST1A6RUFAcGFzczEyMw=='
    //   })
    // };
    
    
    // let options =  new HttpHeaders()
    // options.append('Authorization', 'Basic RVVST1A6RUFAcGFzczEyMw==')
    
    // // let options = {
    // //   headers: new HttpHeaders({
        
    // //      'authorization': `Basic RVVST1A6RUFAcGFzczEyMw==`
    // //    })
    // // }

    // // let headers = new HttpHeaders();
    // // headers = headers.set('authorization', 'Basic RVVST1A6RUFAcGFzczEyMw==');

    // // console.log(form, 'form data');
    // // console.log(options, 'options');
    // return this.http.post(url, form, {headers: options}).pipe(map(data => {
    //   console.log(data, 'result....');
    //   return data;
    // }));
    return this.http.post(this.baseUrl + environment.apiEndPoints.sendMail, form).pipe(map(data => {
      return data;
    }));

    
    
  }
  sendClaimMail(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.sendEmailFromClaims, data).pipe(map(data=>{
       return data;
    }))
  }
  createClaimWhenDelayHappens(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createClaimWhenDelayHappens, data).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsIG(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.makePaymentsIG, data).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsforAllClaims() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.makePaymentsforAllClaims).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsforAllPennyDrops() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.makePaymentsforAllPennyDrops).pipe(map(data => {
      return data;
    }));
  }

  

  

  flightRemainders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.flightRemainders).pipe(map(data => {
      return data;
    }));
  }

  getIGDelayClaim() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getIGDelayClaim).pipe(map(data => {
      return data;
    }));
  }

  IGlistPennyDrops() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.IGlistPennyDrops).pipe(map(data => {
      return data;
    }));
  }

  IGlistPaidClaims() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.IGlistPaidClaims).pipe(map(data => {
      return data;
    }));
  }

  currencyConversion(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.currencyConvertor, data).pipe(map(data => {
      return data;
    }));
  }

  downloadFile(file){
    return this.http.get(`${file}`, {
        responseType : 'blob',
        headers:new HttpHeaders().append('Content-Type','application/json')
    });
}

updatelogIdById(id, logId) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.updatelogIdById+"?_id="+id+"&logId="+logId).pipe(map(data => {
    return data;
  }));
}

// Instant Gratification Functions

getCustomerDataFromClient(page, itemsPerPage, flag) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getCustomerDataFromClient+"?page="+page+"&itemsPerPage="+itemsPerPage+"&flag="+flag).pipe(map(data => {
    return data;
  }));
}

getTransactions(page, itemsPerPage) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.IGTransactions+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
    return data;
  }));
}

getIGDelayClaimByStatus(page, itemsPerPage, status) {
  console.log(page)
  console.log(itemsPerPage)
  console.log(status)
  return this.http.get(this.baseUrl + environment.apiEndPoints.getIGDelayClaimsByStatus+"?page="+page+"&itemsPerPage="+itemsPerPage+"&status="+status).pipe(map(data => {
    return data;
  }));
}

getSMS(page, itemsPerPage, type, customer, fromDate, toDate) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getSMSEmail+"?page="+page+"&itemsPerPage="+itemsPerPage+"&type="+type+"&customer="+customer+"&fromDate="+fromDate+"&toDate="+toDate).pipe(map(data => {
    return data;
  }));
}

getIntimateClaimData() {
  return this.http.get(this.baseUrl + environment.apiEndPoints.intimateClaimsData).pipe(map(data => {
    return data;
  }));
}

getClaimDataFromClient() {
  return this.http.get(this.baseUrl + environment.apiEndPoints.claimDetailsFromClient).pipe(map(data => {
    return data;
  }));
}
searchData(seachInput){
  return this.http.post(this.baseUrl + environment.apiEndPoints.getFileTrackerData, seachInput).pipe(map(data => {
    return data;
  }));
}
downloadFileForFileTracker(fileName) {
  const data ={
    "fileId":fileName
  }
  return this.http.post(this.baseUrl + environment.apiEndPoints.downloadFileForFileTracker ,data).pipe(
    map(data => {
      return data;
    })
  );
 }
 sendPaymentLink(data) {
  return this.http.post(this.baseUrl + environment.apiEndPoints.sendPaymentLink, data).pipe(map(data => {
    return data;
  }));
}
 getPolicyInstances(policyNumber) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getpolicyInstance+"?policyNumber="+policyNumber).pipe(map(data => {
    return data;
  }));
}
  
  getWhatsappTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getwhatapptemplates).pipe(map(data => {
      return data;
    }));
  }
  createWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createwhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }
  updateWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatewhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }
  deleteWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletewhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }

}
